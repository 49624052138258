import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query, SanityTestimonialConnection } from "@graphql-types";
import { Hero } from "@global";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import TestimonialsGrid from "@components/testimonials/testimonialsGrid";

interface Data extends Query {
  testimonials: SanityTestimonialConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function TestimonialsPage({ data }: Props) {
  if (data.sanityTestimonialsPage == null) {
    return null;
  }

  const { seo, hero } = data.sanityTestimonialsPage;
  const { testimonials } = data;

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        <TestimonialsGrid data={testimonials.nodes} />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityTestimonialsPage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
    }
    testimonials: allSanityTestimonial {
      nodes {
        ...sanityTestimonial
      }
    }
  }
`;

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { SanityTestimonial } from "@graphql-types";
import { Container, P } from "@util/standard";
import { BlocksContent } from "@global";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityTestimonial[];
}

const StyledContainer = styled(Container)<{ averageHeight?: number; testimonialCount: number }>`
  display: flex;
  flex-flow: column wrap;

  ${({ testimonialCount, averageHeight }) =>
    testimonialCount &&
    averageHeight &&
    `height: calc((${testimonialCount} / 3) *  ${averageHeight * 1.2}px );`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ testimonialCount, averageHeight }) =>
      testimonialCount &&
      averageHeight &&
      `height: calc((${testimonialCount} / 2) *  ${averageHeight * 1.1}px );`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: max-content;
  }
`;

const TestimonialWrapper = styled(Container)`
  flex-direction: column;
  height: fit-content;
  padding: 80px 7%;
  width: 18%;
  margin: 1% 0.5%;
  border-radius: 10px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 33%;
    margin: 1%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 85%;
  }
`;

function TestimonialsGrid({ data }: Props) {
  if (!data) return null;

  const [totalSumOfHeights, setTotalSumOfHeights] = useState(0);
  const [averageHeight, setAverageHeight] = useState<number>();

  useEffect(() => {
    if (totalSumOfHeights > 0) {
      const average = totalSumOfHeights / data.length + 1;

      setAverageHeight(average);
    }
  }, [totalSumOfHeights, averageHeight]);

  return (
    <StyledContainer
      width="82%"
      margin="100px auto"
      testimonialCount={data.length + 1}
      averageHeight={averageHeight}
    >
      {data.map((testimonial, index) => {
        const ref = useRef<HTMLDivElement>(null);
        const defineColor =
          index % 3 === 0 ? "lightBlue" : (index + 1) % 2 === 0 ? "cream" : "mint";

        useEffect(() => {
          if (ref.current) {
            const height = ref.current.getBoundingClientRect().height;
            setTotalSumOfHeights(prev => prev + height);
          }
        }, [ref]);

        return (
          <TestimonialWrapper ref={ref} backgroundColor={defineColor} key={testimonial._id}>
            <BlocksContent data={testimonial.statement} />
            <P fontWeight="medium">{testimonial.attestant}</P>
          </TestimonialWrapper>
        );
      })}
    </StyledContainer>
  );
}

export default TestimonialsGrid;
